.wd-login[data-v-bdc84c8e] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wd-login .btn-submit[data-v-bdc84c8e] {
  text-align: center;
}
.wd-login .btn-submit[data-v-bdc84c8e] .van-button {
  width: 60%;
}
.wd-login .logo[data-v-bdc84c8e] {
  text-align: center;
  margin-top: -0.5rem;
}
.wd-login .logo img[data-v-bdc84c8e] {
  display: inline-block !important;
}
